<template>
  <div id="search">
    <bread class="nav" :title="title" :subtitle="subtitle"></bread>
    <div class="searchTitle tl">
      您本次搜索的关键词为
      <span class="sameMark">{{this.$route.query.key}}</span>，为您找到
      <span class="sameMark">{{tableParams.total==null?'0':tableParams.total}}</span>条结果
    </div>
    <div class="detail tl">
      <ul>
        <li v-for="(item,index) in tableData" :key="index">
          <span>{{(tableParams.pageCurrent-1)*tableParams.pageSize+index+1}}</span>
          <a
            href="javascript:;"
            @click="lookDetail( item.data_id ? item.data_id : item.id,item.data_id ? item.data_id : '公示公告',item.jyfs)"
          >{{item.title}}</a>
          <span style="float:right">{{item.createtime | filterTime}}</span>
        </li>
      </ul>
      <template v-if="tableData!=''">
        <div class="pagination">
          <el-pagination
            @size-change="sizeChange"
            @current-change="currentChange"
            :page-size="10"
            layout="prev, pager, next, jumper"
            :current-page="1"
            :total="tableParams.total"
            style="margin-top: 40px;padding-bottom: 20px;text-align: center;"
          ></el-pagination>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import bread from "@/components/breadcrumb";
export default {
  components: {
    bread
  },
  data() {
    return {
      title: "搜索结果",
      subtitle: "",
      keyValue: this.$route.query.key,
      tableData: [],
      tableParams: {
        pageSize: 10,
        pageCurrent: 1,
        total: null
      }
    };
  },
  watch: {
    $route() {
      this.$route.query.key;
      this.getData();
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData(key) {
      let param = {
        gjz: this.$route.query.key,
        pageNum: this.tableParams.pageCurrent,
        pageSize: this.tableParams.pageSize
      };

      this.apis.api.getSearchKeywords(param).then(res => {
        if (res.data.code == "01") {
          this.tableData = res.data.dat;
          this.tableParams.total = res.data.count;
        }
      });
    },
    // 跳转到详情
    lookDetail(id, dataId,jyfs) {
      this.$router.push({
        name: "noticeDetail",
        query: {
          id: id,
          pathName: "搜索结果",
          child: "搜索结果",
          dataId: dataId,
			jyfs:jyfs
        }
      });
    },

    // 切换分页
    sizeChange(val) {
      var _this = this;
      _this.tableParams.pageSize = val;
      //方法  传参
      _this.getData();
      //方法  传参
    },
    // 分页
    currentChange(val) {
      var _this = this;
      _this.tableParams.pageCurrent = val;
      _this.getData();
    }
  }
};
</script>
<style  lang="scss" scoped>
#search {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 20px;
  min-height: 400px;
  background-color: #fff;
  .searchTitle {
    font-size: 16px;
    margin-bottom: 20px;
    padding-left: 20px;
    .sameMark {
      color: #0e83c9;
      margin: 0 5px;
    }
  }
  .detail {
    // position: relative;
    width: 100%;
    min-height: 300px;
    li {
      padding: 0 15px;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px dashed #ddd;
      span:nth-child(1) {
        display: inline-block;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        border-radius: 50%;
        color: #fff;
        margin-right: 5px;
        background: #0e83c9;
        position: relative;
        top: -15px;
      }
      a {
        display: inline-block;
        // width: 488px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      a:hover {
        color: #0e83c9;
      }
    }
    .pagination {
      margin-top: 20px;
      text-align: center;
      // position: absolute;
      // bottom: 20px;
      // left: 0;
      // width: 100%;
      // text-align: center;
    }
  }
}
</style>
